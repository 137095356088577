<template>
  <div>
    <div class="">
      <label>
        <v-combobox :items="options" v-model="selected" dense></v-combobox>
      </label>
    </div>
  </div>
</template>

<script>
export default {
   name: 'DiscountChanger',
  props: [
    'item',
    'field'
  ],
  computed: {
    options () {
      return this.$store.getters['general/getDiscountOptions']
    },
    selected: {
      get () {
        return this.item[this.field]
      },
      set (value) {
        this.$emit('start-loading')
        this.$http.patch(`products/${this.item.id}`, {[this.field]: value})
          .then(() => {
            this.$emit('reload')
          })
          .catch(() => {
            this.$vs.notify({
              'title': 'Eroare!',
              'color': 'danger',
              'text': 'Eroare la salvare! Va rugam incercati mai tarziu'
            })
            this.$emit('stop-loading')
          })
      }
    }
  }
}
</script>
