<template>
  <div class="select-filter">
    <v-select
        v-model="selectOption"
        :items="options"
        :label="placeholder"
        class="filter-select"
        dense
        hide-details
        item-text="value"
        item-value="id"
        outlined
        single-line
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'TrashStatusFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      selectOption: 'without',
      options: [
        {
          id: 'with',
          value: 'Toate produsele'
        },
        {
          id: 'only',
          value: 'Doar cele sterse'
        },
        {
          id: 'without',
          value: 'Doar active'
        }
      ]
    }
  },
  watch: {
    selectOption () {
      const value = this.selectOption || ''
      this.$emit('filter', value, this.filterName)
    }
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
