<template>
  <div class="inline-block">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-xl font-bold text-center">
          Selecteaza discountul pentru produsele selectate
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-combobox :items="discountOptions" v-model="discount_end_users" dense></v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false">Anuleaza</v-btn>
          <v-btn text @click="update">Salveaza</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vs-tooltip text="Modificarea discounturilor pentru toate produsele selectate">
      <v-btn class="mx-2 primary-button" color="primary"  small @click="dialog = true">
        <i class="fal fa-percent mr-2"></i>Actualizeaza discount end-user
      </v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
import DeleteButton from '@/components/general-form/DeleteButton'

export default {
  name: 'UpdateSelectedDiscountEndUser',
  components: {DeleteButton},
  props: {
    table: {
      required: true,
      default () {
        return {}
      }
    },
    selectedItems: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    selectedIds () {
      return this.selectedItems.map(item => item.id)
    },
    discountOptions () {
      return this.$store.getters['general/getDiscountOptions']
    }
  },
  data () {
    return {
      discount_end_users: 1,
      dialog: false
    }
  },
  methods: {
    update () {

      this.table.startLoading()
      this.table.calculateQueryString()

      this.$http.patch(`products/update-discount?${this.table.queryString}`, {
        all_selected: this.table.bulkAll,
        products: this.selectedIds,
        filter: this.table.allFilters(),
        discount_end_users: this.discount_end_users
      })
        .then(() => {
          this.dialog = false
          this.table.loadItems()
        })
        .catch(() => {
          this.table.stopLoading()
        })

    }
  }
}
</script>

