<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on:tooltipOn, attrs:tooltipAttrs }">
          <v-btn
            v-bind="{...tooltipOn, ...attrs}"
            v-on="{ ...tooltipOn, ...on}"
            class="mx-2 mb-2 secondary-button"
            color=" lighten-4"
          >
            <v-icon left>fal fa-download</v-icon>
            Exporta Produsele
          </v-btn>
        </template>
        <span>
            Click pentru a genera exportul de <strong>produse</strong>: <br/>
            - Din categoria curenta<br/>
            - Filtrate <br/>
            - Fara paginare
          </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Selecteaza Coloanele</v-card-title>
      <v-divider/>
      <v-card-text style="height: 400px;">
        <template v-if="loading">
          <loader :loading="loading" colors="rgba(255,255,255,0.5)" loader-color="red">
            <span class="text-dark mt-4">Se genereaza.</span>
            <span class="text-dark mt-2">Va rugam asteptati.</span>
            <span class="text-dark mt-2">Pastrati pagina pornita cat timp se exporta.</span>
          </loader>
        </template>

        <template v-else>
          <v-radio-group v-model="columns" column>
            <v-checkbox
              v-for="column in columns"
              v-model="selected"
              :label="column.label"
              :value="column.key"
              hide-details
            ></v-checkbox>
          </v-radio-group>
        </template>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn :disabled="loading" color="grey darken-1" text @click="closeDialog">
          Renunta
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="loading" color="primary" class="primary-color" text @click="downloadProducts">
          Exporta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  name: 'ExportProducts',
  components: {Loader},
  inject: ['table'],
  data () {
    return {
      loading: false,
      columns: {},
      dialog: false,
      selected: [],
      error: ''
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    getProductColumns () {
      this.$http.get('products/export/columns')
        .then(({data}) => {
          this.columns = data
          this.selected = data.map(item => item.key)
        })
    },
    downloadProducts () {
      this.loading = true
      this.table.calculateQueryString()

      this.$http.get(
        `products/export?${this.table.queryString}`,
        {
          params: {
            columns: this.selected
          }
        }
      )
        .then(({data}) => {
          const uuid = data.uuid
          this.downloadFile(uuid)
        })
        .catch((e) => {
          console.log(e)
          this.$vs.notify({
            'title': 'Eroare',
            'text': 'Eroare la Export, va rugam reimprospatati pagina sau incercati mai tarziu',
            'color': 'danger'
          })
          this.loading = false
        })


    },
    downloadFile (uuid) {
      this.$http.get(`products/export/${uuid}`, {responseType: 'arraybuffer'})
        .then(({data}) => {
          const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = url
          a.download = uuid + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
          this.loading = false
        })
        .catch(() => {
          this.downloadFile(uuid)
        })
    }
  },
  created () {
    this.getProductColumns()
  }
}
</script>
