<template>
  <div class="select-filter">
    <v-autocomplete
      v-model="selectOption"
      :items="items"
      :label="label"
      :loading="loading"
      :return-object="returnObject"
      :search-input.sync="search"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="id"
      outlined
      single-line
    >

      <template v-if="hasLoadMore" v-slot:append-item>
        <v-divider class="mb-2"/>
        <v-list-item :disabled="loading" @click="loadMore">
          <v-list-item-avatar v-show="loading" color="grey lighten-3">
            <v-icon x-small>
              fal fa-spinner fa-spin fa-sm
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-avatar v-show="!loading" color="grey lighten-3">
            <v-icon x-small>
              fal fa-ellipsis-h
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              Incarca mai multe...
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'BaseProductsFilter',
  props: {
    value: {},
    returnObject: {
      default: false
    },
    label: {
      default: 'Produs de baza'
    },
    parentCategoryId: {
      default: false
    },
    filterName: {
      required: true
    }
  },
  data () {
    return {
      items: [],
      selectOption: '',
      loading: false,
      search: '',
      page: 1,
      hasLoadMore: true
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.page = 1
      this.loadItems(val)
    },
    selectOption (val) {
      const filter = val || ''
      this.$emit('filter', filter, this.filterName)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      const categoryFilter = this.parentCategoryId ? `&filter[category]=${this.parentCategoryId}` : ''

      this.$http.get(`/base-products/all?page=${this.page}&filter[name]=${search}${categoryFilter}`)
        .then(({data}) => {
          if (this.page > 1) {
            this.items = this.items.concat(data.data)
          } else {
            this.items = data.data
          }
          this.hasLoadMore = data.meta.last_page > this.page
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })

    },
    loadMore () {
      this.page = this.page + 1
      this.loading = true
      this.loadItems(this.search)
    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
